import React, { FC } from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import Img from 'gatsby-image';
import { Layout } from '../components/Layout/Layout';

import { SEO } from '../components/SEO/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

type PageContextCatTemplate = {
  catid: string;
  catname: GatsbyTypes.Maybe<string>;
  catslug: GatsbyTypes.Maybe<string>;
  skip: number;
  limit: number;
  currentPage: number;
  isFirst: boolean;
  isLast: boolean;
};

const CatTemplate: FC<
  PageProps<GatsbyTypes.CatTemplateQuery, PageContextCatTemplate>
> = ({ data, location, pageContext }) => (
  <Layout>
    <SEO
      pagetitle={`CATEGORY: ${pageContext.catname}`}
      pagedesc={`「${pageContext.catname}」カテゴリーの記事です`}
      pagepath={location.pathname}
    />
    <section className="content bloglist">
      <div className="container">
        <h1 className="bar">CATEGORY: {pageContext.catname}</h1>
        <div className="posts">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <article className="post" key={node.id}>
              <Link to={`/blog/post/${node.slug}/`}>
                <figure>
                  {node?.eyecatch?.fluid ? (
                    <Img
                      fluid={node.eyecatch.fluid}
                      alt={node.eyecatch.description}
                      style={{ height: '100%' }}
                    />
                  ) : (
                    ''
                  )}
                </figure>
                <h3>{node.title}</h3>
              </Link>
            </article>
          ))}
        </div>
        <ul className="pagenation">
          {!pageContext.isFirst && (
            <li className="prev">
              <Link
                to={
                  pageContext.currentPage === 2
                    ? `/cat/${pageContext.catslug}/`
                    : `/cat/${pageContext.catslug}/${
                        pageContext.currentPage - 1
                      }/`
                }
                rel="prev"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>前のページ</span>
              </Link>
            </li>
          )}
          {!pageContext.isLast && (
            <li className="next">
              <Link
                to={`/cat/${pageContext.catslug}/${
                  pageContext.currentPage + 1
                }/`}
                rel="next"
              >
                <span>次のページ</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query CatTemplate($catid: String!, $skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: $skip
      limit: $limit
      filter: { category: { elemMatch: { id: { eq: $catid } } } }
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`;

export default CatTemplate;
